.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:999;
  }
  
  .loading-content {
    text-align: center;
  }
  
  .loading-content img {
    width: 100px; /* Adjust width as needed */
    height: auto; /* To maintain aspect ratio */
  }
  